var ClipboardJS = require('clipboard');
import { HTTPDataSvc } from 'bioniclogic-client'
import { AjaxResult } from 'bionicdataapi';

var httpSvc = new HTTPDataSvc({
    kind: "httpcreds",
    baseUrl: ""
});

declare global {
    interface Window {
        app: App;
        supernova: any;
    }
}

var supernova = (<any>window).supernova;

async function getjsbin(svcname: string, args?: string): Promise<any> {
    return await httpSvc.getDataByPath("/www/jsbin/" + svcname + (args ? "?" + args : ""), null);
}
async function putjsbin(svcname: string, data: any, args?: string): Promise<any> {
    return await httpSvc.putDataByPath("/www/jsbin/" + svcname + (args ? "?" + args : ""), data, null);
}
async function postjsbin(svcname: string, data: any, args?: string): Promise<any> {
    return await httpSvc.postDataByPath("/www/jsbin/" + svcname + (args ? "?" + args : ""), data);
}

class App {

    public async submitForm(ev: Event) {
        this.showAlert("Submitting form");
        ev.preventDefault();
        var form = (<any>(ev.target)).form;
        var url = form.getAttribute('action');
        
        var method = form.getAttribute('method');
        var successurl = form.getAttribute('data-success-url');

        var req: RequestInit = {
            method: method,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: $(form).serialize(),
            credentials: 'same-origin',
        }
        try {
            var res: any = await fetch(url, req).then((r) => r.json());
            if (res && res.status == "success") {
                this.showAlert(res.status);
                setImmediate(() => {
                    window.location.href = successurl;
                });
            } else {
                this.showAlert(res.status + (res.message ? res.message : ""));
            }
            return res; 
            console.log(res);
        } catch (err) {
            this.showAlert("An error occured: " + err);
            return null;
            console.log(err);
        }
    }

    public showAlert(message: string, classList?: string, timeout?: number) {
        var alertBox = <HTMLElement>document.createElement("div");
        alertBox.className = classList ? classList : "alert alert-success";
        alertBox.innerHTML = message;
        $("#alerts").append(alertBox);

        setTimeout(function () {
            alertBox.style.opacity = "0";
        }, timeout ? timeout : 2000);

        setTimeout(function () {
            alertBox.parentElement.removeChild(alertBox);
        }, timeout ? timeout + 500 : 2500);
    }

    public initClipboard(selector: string, cb?: (e: any) => void) {
        var self = this;
        var clipboard = new ClipboardJS(selector);
        if (cb == null) {
            cb = function () {
                self.showAlert("Copied to clipboard");
            }
        }
        clipboard.on('success', function (e: any) {
            cb(e);
            e.clearSelection();
        });
    }
    public async init(page: string) {
        var requiredHosts = ["www.getsupernova.com","getsupernova.datamat.io"];
        if(page.startsWith("/dashboard/")) {
            requiredHosts = ["go.getsupernova.com"];
        } else if(page == "/resetpassword" || page == "/setpassword" || page == "/login" || page == "/register") {
            requiredHosts = ["go.getsupernova.com"];
        }
        if(requiredHosts.indexOf(window.location.host)<0) {
            window.location.href = window.location.protocol + "//"+ requiredHosts[0] +  window.location.pathname + window.location.search;
        }


        switch (page) {
            case "/resetpassword": {
                //get the settoken from the url then set it to the input within the form
                //@ts-ignore
                var params: any = window.location.search.substr(1).split('&').reduce(function (a, b) { var c = b.split("=").map(decodeURIComponent); if (c[1]) a[c[0]] = c[1]; return a; }, {});
                let settoken = params["guid"];
                if (!settoken) break;

                let expired: AjaxResult;
                try {
                    //make sure that this password reset attempt hasn't expired 
                    expired = await getjsbin("resetdataexpired", "guid=" + settoken);
                } catch (e) {
                    //don't crash the app
                }

                let splashscreen = <HTMLElement>document.querySelector("#splashscreen");
                splashscreen.style.display = "none";
                let rpasswordframe = <HTMLElement>document.querySelector("#passwordframe");
                let errorFrame = <HTMLElement>document.querySelector("#errorframe");
                if (expired.message == "expired") {
                    let title = errorFrame.querySelector(".title");
                    let message = errorFrame.querySelector(".message");

                    title.innerHTML = "Expired";
                    message.innerHTML = "This password reset link has expired. Please generate a new one and try again";

                    rpasswordframe.style.display = "none";
                    errorFrame.style.display = "block";
                } else {
                    rpasswordframe.style.display = "block";
                }


                let settokeninput = <HTMLInputElement>document.querySelector("#settoken");
                settokeninput.value = settoken;
                break;
            }
            case "/setpassword":
                //get the settoken from the url then set it to the input within the form

                //@ts-ignore
                var params: any = window.location.search.substr(1).split('&').reduce(function (a, b) { var c = b.split("=").map(decodeURIComponent); if (c[1]) a[c[0]] = c[1]; return a; }, {});
                let settoken = params["guid"];

                //const urlParams = new URLSearchParams(window.location.search);
                //let settoken = urlParams.get("guid");

                //let url = window.location.href;
                //let parts = url.split("/");
                //let settoken = parts[parts.length-1];
                if (!settoken) break;
                let settokeninput = <HTMLInputElement>document.querySelector("#settoken");
                settokeninput.value = settoken;
                break;
            case "/setup":
                this.regenerateExtensionTag({});
                this.regenerateTag({});
                this.initClipboard("#copyextensiontag");
                this.initClipboard("#copyplayertag");
                break;
        }

    }

    public async setpassword(ev:any){
        let res:AjaxResult = await this.submitForm(ev);

        let success_ = <HTMLElement> document.querySelector("#success_setpassword");
        let error_ = <HTMLElement> document.querySelector("#error_setpassword");

        let passwordframe = <HTMLElement>document.querySelector("#passwordframe");        
        passwordframe.style.display = "none";

        success_.style.display = "none";
        error_.style.display = "none";
        if(res.status == "success"){
            success_.style.display = "block";
        }else{
            error_.style.display = "block";
        }

    }

    public async forgotpassword(ev: any) {
        ev.preventDefault();
        let resetinstructions = <HTMLDivElement>document.querySelector("#resetinstructions");
        let resetinstructionscontent = <HTMLParagraphElement>document.querySelector("#resetinstructionscontent");
        let usernameinput = <HTMLInputElement>document.querySelector("#email");
        let username = usernameinput.value;
        if (!username) this.showAlert("Username Required"); //cant do anything without a username

        let res = await postjsbin("requestpasswordreset", { username: username }).catch(function (e) { console.log(e) });
        let status = res.status;
        let message = res.message;

        resetinstructionscontent.innerHTML = message;
    
        resetinstructions.style.display = "block";
    }

    public tryExtensionPrompt() {
        var supernova = (<any>window).supernova;
        try {
            supernova.closeExtensionPrompt();
            supernova.closeExtensionEmbedBar();
        } catch (e) { }

        supernova.launch({
            mode: 'extensionprompt',
            partnerlogo: "https://cdn.getsupernova.com/images/star.png",
            title: "Dungeon Punks",
            icon: "http://gamemedia.armorgames.com/18089/icn_thmb.png",
            force: true,
            el: document.getElementById("game")
        });
    }

    public regenerateExtensionTag(options: any) {
        let extensioncode = '<scr' + 'ipt src="https://cdn.getsupernova.com/tags/supernovalauncher.js"></scr' + 'ipt>\n\
<scr' + 'ipt>\n\
supernova.launch({\n\
mode: "extensionprompt",\n\
el: document.querySelector("#game"),\n\
partnerlogo: "",\n\
title: "",\n\
icon: "",\n\
embedbar: true,\n\
dropdown: true,\n\
});\n\
</scr'+ 'ipt>';
        (<HTMLTextAreaElement>document.getElementById("extensiontag")).value = extensioncode;
    }

    public regenerateTag(options: any) {
        let playercode = '<scr' + 'ipt src="https://cdn.getsupernova.com/tags/supernovalauncher.js"></scr' + 'ipt>\n\
supernova.launch({\n\
el: document.querySelector("#game"),\n\
swfurl: "https://cdn.getsupernova.com/games/dungeonpunks.swf",\n\
flashvars: "",\n\
title: "Dungeon Punks",\n\
icon: "https://cdn.getsupernova.com/images/dungeonpunks_icon.png",\n\
width: 960,\n\
height: 540\n\
});\n\
</scr' + 'ipt>';
        (<HTMLTextAreaElement>document.getElementById("playertag")).value = playercode;
    }


}
let app = new App();
window.app = app;

var path = window.location.pathname;
if(path[path.length-1] == "/") {
    path = path.slice(0,path.length-1);
}
app.init(path);

export default App;